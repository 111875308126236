import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import '@/lib/utils/forwardRef';
import ChevronLeft from '@/icons/light/chevron-left.svg';
import ChevronRight from '@/icons/light/chevron-right.svg';

import { LinkVariant } from './types';

import type { LinkProps } from './types';

const VARIANT_MAPS: Record<LinkVariant, string> = {
  [LinkVariant.POSITIVE]: 'text-primary-500 hover:text-primary-700',
  [LinkVariant.NEGATIVE]: 'text-neutrals-0 hover:text-primary-800',
};

const LinkBase = <T extends ElementType = 'a'>(
  {
    className = '',
    variant = LinkVariant.POSITIVE,
    children,
    as,
    disabled = false,
    icon,
    ...props
  }: LinkProps<T> & Omit<ComponentPropsWithRef<T>, keyof LinkProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'a';

  const DISABLED =
    variant === LinkVariant.NEGATIVE ? 'text-primary-300' : 'text-primary-200';

  return (
    <HTMLTag
      className={oneLine`
        text-body text-body--m inline-flex items-center gap-x-1 transition-colors duration-300 ease-in-out
        ${VARIANT_MAPS[variant]}
        ${disabled ? { DISABLED } : ''}
        ${className}
      `}
      ref={ref}
      {...props}
    >
      {icon === 'left' ? <ChevronLeft width={16} height={16} /> : null}
      {children}
      {icon === 'right' ? <ChevronRight width={16} height={16} /> : null}
    </HTMLTag>
  );
};

export const Link = forwardRef(LinkBase);
