import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import Spinner from '@/icons/light/refresh-ccw.svg';

import '@/lib/utils/forwardRef';
import { ButtonSize, ButtonVariant } from './types';

import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import type { ButtonProps } from './types';

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]:
    'bg-primary-500 text-neutrals-0 hover:bg-primary-600',
  [ButtonVariant.SECONDARY]:
    'bg-secondary-500 hover:bg-secondary-400 text-text-neutrals-900',
  [ButtonVariant.ELEVATED]:
    'bg-neutrals-0 hover:bg-primary-50 text-text-neutrals-900',
  [ButtonVariant.ALTERNATIVE]:
    'bg-neutrals-0 hover:bg-primary-50 border-primary-600 text-primary-600',
};

const SIZE_MAPS: Record<ButtonSize, string> = {
  [ButtonSize.SMALL]: 'py-2.5',
  [ButtonSize.MEDIUM]: 'py-3',
  [ButtonSize.LARGE]: 'py-[18px]',
};

const TEXT_LINK =
  'text-button text-primary-500 bg-neutrals-0 hover:!bg-primary-50 hover:text-primary-500 disabled:!bg-primary-50';

/**
 * Botón
 */
const ButtonBase = <T extends ElementType = 'button'>(
  {
    className = '',
    variant,
    size,
    as,
    children,
    outline = false,
    link = false,
    loading = false,
    ...props
  }: ButtonProps<T> & Omit<ComponentPropsWithRef<T>, keyof ButtonProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'button';
  const OUTLINE =
    variant === ButtonVariant.PRIMARY
      ? '!bg-transparent border-solid border-primary-500 text-button !text-primary-500 hover:!bg-primary-50 hover:text-primary-500 hover:border-primary-500 disabled:!bg-primary-50 disabled:border-none disabled:opacity-100'
      : variant === ButtonVariant.ELEVATED
      ? '!bg-transparent border-solid border-neutrals-900 text-button !text-neutrals-900 hover:!text-primary-900 hover:!bg-primary-50 hover:text-neutrals-800 disabled:!bg-transparent disabled:border-none disabled:opacity-100'
      : '';

  return (
    <HTMLTag
      className={oneLine`
        text-button flex w-full
        min-w-[120px] cursor-pointer items-center justify-center gap-x-3 rounded border px-4 text-center transition-colors duration-300 ease-in-out disabled:pointer-events-none
        disabled:opacity-30 aria-disabled:pointer-events-none aria-disabled:opacity-30 md:w-auto
        ${VARIANT_MAPS[variant]}
        ${SIZE_MAPS[size]}
        ${outline ? OUTLINE : 'border-transparent'}
        ${link ? TEXT_LINK : ''}
        ${className ?? ''}
      `}
      ref={ref}
      {...props}
    >
      {loading ? (
        <Spinner className="h-4 w-4 animate-spin motion-reduce:animate-none" />
      ) : (
        children
      )}
    </HTMLTag>
  );
};

export const Button = forwardRef(ButtonBase);
