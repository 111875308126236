/**
 * Create a hash 256 from the given string
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 * @see https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
 *
 * @param {string} string The string to hash
 * @returns {Promise<string>} The hash 256 of the given string
 */
export const hash256 = async (string: string): Promise<string> => {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
};

/**
 * Create a user id
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @returns {string} The user id
 */
export const getUserID = (): string => {
  const userID = localStorage.getItem('userID');

  if (userID) {
    return userID;
  } else {
    let newUserID = '';
    (async () =>
      await hash256(Date.now().toString()).then((hash) => {
        localStorage.setItem('userID', hash);
        newUserID = hash;
      }))();
    return newUserID;
  }
};
