import Link from 'next/link';

import type { FunctionComponent } from 'react';
import type { LegalProps } from './types';

export const Legal: FunctionComponent<LegalProps> = ({
  className = '',
  legales,
}: LegalProps) => (
  <div
    className={`text-body text-body--m text-link flex flex-col gap-2 text-neutrals-0 md:gap-8 lg:flex-row ${className}`}
  >
    {legales?.map((legal, index: number) =>
      typeof legal !== 'string' ? (
        <Link key={index} href={legal.href} legacyBehavior>
          <a className="my-1 text-text-neutrals-0 md:my-0">{legal.label}</a>
        </Link>
      ) : (
        legal
      )
    )}
  </div>
);

Legal.displayName = 'Legal';
