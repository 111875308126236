import type { FunctionComponent } from 'react';
import type { SocialMediaProps, TypeSocialMedia } from './types';

export const SocialMedia: FunctionComponent<SocialMediaProps> = ({
  className = '',
  socialmedias,
  socialMediaTitle,
}: SocialMediaProps) => (
  <div className={`flex items-center gap-4 text-neutrals-0 ${className}`}>
    <span className="text-body text-body--l font-bold">{socialMediaTitle}</span>
    <div className="flex flex-row gap-4 text-neutrals-0">
      {socialmedias?.map((socialMedia: TypeSocialMedia, index: number) => (
        <a
          href={socialMedia.href}
          key={`${index}_${socialMedia.href}`}
          rel="noopener noreferrer"
          target="_blank"
          className="text-text-neutrals-0"
        >
          {socialMedia.icon}
        </a>
      ))}
    </div>
  </div>
);

SocialMedia.displayName = 'SocialMedia';
