import { Children, isValidElement } from 'react';

import type {
  ElementType,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react';

/**
 * Check if it has a child of the type passed by parameter
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {ReactNode | undefined} children List of children to search
 * @param {ElementType} child Type of the child to check
 * @returns {boolean} True if the child is found
 */
export const hasChild = (
  children: ReactNode | undefined,
  child: ElementType
): boolean => {
  const types = Children.map(children, (item) => {
    if (!isValidElement(item)) return null;
    return item.type;
  });

  return (types || []).includes(child);
};

/**
 * Get a child of the type passed by parameter
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {ReactNode | undefined} children List of children to search
 * @param {ElementType} targetChild Type of the child to get
 * @returns {[ReactNode | undefined, ReactElement | undefined]} Array with the rest of children and the target child
 */
export const pickChild = (
  children: ReactNode | undefined,
  targetChild: ElementType
): [ReactNode | undefined, ReactElement | undefined] => {
  let target: ReactNode = null;
  const withoutTargetChildren = Children.map(children, (item) => {
    if (!isValidElement(item)) return item;
    if (item.type === targetChild) {
      target = item;
      return null;
    }
    return item;
  });

  const targetChildren = target !== null ? target : undefined;

  return [withoutTargetChildren, targetChildren];
};

/**
 * Get a list of child of the type passed by parameter
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {ReactNode | undefined} children List of children to search
 * @param {ElementType} targetChild Type of the child to get
 * @returns {[ReactNode | undefined, ReactElement | undefined]} Array with the rest of children and the target children
 */
export const pickChilds = (
  children: ReactNode | undefined,
  targetChild: ElementType
): [ReactNode | undefined, ReactElement[] | undefined] => {
  const target: ReactElement[] = [];
  const withoutTargetChildren = Children.map(children, (item) => {
    if (!isValidElement(item)) return item;
    if (item.type === targetChild) {
      target.push(item);
      return null;
    }
    return item;
  });

  const targetChildren = target.length >= 0 ? target : undefined;

  return [withoutTargetChildren, targetChildren];
};

/**
 * Inyect a class in all children
 *
 * For the correct operation of this function, all children must allow the prop className
 *
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} className Class to inyect
 * @param {ReactNode | ReactNode[]} children List of children to inyect
 * @returns {ReactNode[] | ReactNode} List of children with the class inyected
 */
export const classInjector = (
  className = '',
  children: ReactNode | ReactNode[]
): ReactNode[] | ReactNode => {
  const StyledChildren = (): ReactNode[] | ReactNode =>
    Children.map(
      // TODO Check types
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      children,
      (
        child: ReactElement<any, string | JSXElementConstructor<any>> | string
      ) =>
        child && typeof child !== 'string' ? (
          // TODO Check types
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          <child.type
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            {...child.props}
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            className={`${child.props.className ?? ''} ${className}`}
          />
        ) : (
          child
        )
    );

  // TODO Check types
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return <StyledChildren />;
};

/**
 * Inyect styles in all children
 *
 * For the correct operation of this function, all children must allow the prop styles
 *
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {object} style Styles to inyect
 * @param {ReactNode | ReactNode[]} children List of children to inyect
 * @returns {ReactNode[] | ReactNode} List of children with the styles inyected
 */
export const styleInjector = (
  style = {},
  children: ReactNode | ReactNode[]
): ReactNode[] | ReactNode => {
  const StyledChildren = (): ReactNode[] | ReactNode =>
    Children.map(
      // TODO Check types
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      children,
      (
        child: ReactElement<any, string | JSXElementConstructor<any>> | string
      ) =>
        child && typeof child !== 'string' ? (
          // TODO Check types
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          <child.type
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            {...child.props}
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            style={{ ...(child.props.style ?? {}), ...style }}
          />
        ) : (
          child
        )
    );

  // TODO Check types
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return <StyledChildren />;
};
