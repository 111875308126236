import Link from 'next/link';

import { ConditionalWrapper } from '@/hoc';

import type { FunctionComponent } from 'react';
import type { TypeFlyOutProps } from './types';

/**
 *
 * FlyOut
 */
export const FlyOut: FunctionComponent<TypeFlyOutProps> = ({
  className = '',
  menu,
  isActive,
}: TypeFlyOutProps) => (
  <div
    className={`w-full !bg-neutrals-0 lg:rounded-lg lg:border lg:border-bg-neutrals-50 lg:drop-shadow-lg ${className}`}
  >
    <ul className="flex flex-col lg:p-1">
      {menu.map((item, index: number) => (
        <li key={index}>
          <ConditionalWrapper
            condition={Boolean(item.href)}
            wrapper={(children) => (
              <Link
                href={item.href}
                prefetch={false}
                className="flex w-full items-center justify-between"
              >
                {children}
              </Link>
            )}
          >
            <div
              className={`
                ${
                  isActive && isActive(item.href)
                    ? 'text-text-blue-500'
                    : 'text-text-neutrals-900'
                }
                text-body-l text-body w-full border-b border-bg-neutrals-50 py-4
                px-6 lg:text-body lg:text-body--m lg:rounded-lg lg:border-none lg:p-4 lg:font-normal lg:text-neutrals-900 lg:hover:bg-neutrals-50
              `}
            >
              {item.title}
            </div>
          </ConditionalWrapper>
        </li>
      ))}
    </ul>
  </div>
);

FlyOut.displayName = 'FlyOut';
