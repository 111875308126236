import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { oneLine } from 'common-tags';

import { Button, Hamburger } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';

import LogoBlack from '@/assets/images/LogoNestar-Black.png';
import { useFixPortalToBody, useRouteChangeStart } from '@/hooks';
import { routes } from '@/lib';
import { NavItem } from '../nav-item/NavItem';

import type { FunctionComponent } from 'react';
import type { TypeMobileNavProps } from './types';

/**
 * MobileNav
 */
export const MobileNav: FunctionComponent<TypeMobileNavProps> = ({
  menu,
  socials,
  theme,
  open,
  setOpen,
  topBar,
}: TypeMobileNavProps) => {
  const { asPath: slug } = useRouter();
  const social = socials?.networks.map((ele, index: number) => (
    <Link key={index} href={ele.href} legacyBehavior prefetch={false}>
      <a className="text-text-neutrals-900">
        <ele.Icon width={24} />
      </a>
    </Link>
  ));

  const initialNavItemsState = new Array(menu.length).fill(false);
  const [navItemStates, setNavItemStates] = useState(initialNavItemsState);

  const getUpdatedNavItemsStates = (navItemIndex: number) => {
    const navItemsStatesClone = [...navItemStates];
    navItemsStatesClone[navItemIndex] = !navItemsStatesClone[navItemIndex];

    return navItemsStatesClone;
  };

  useRouteChangeStart(() => {
    setOpen(false);
    setNavItemStates(initialNavItemsState);
  });
  useFixPortalToBody(open);

  return (
    <nav
      className="flex h-full w-full justify-end lg:hidden"
      role="navigation"
      aria-label="Menú principal"
    >
      <div
        className={oneLine`
          relative z-50 flex w-full flex-row items-center justify-end bg-neutrals-0 px-4
          ${open ? 'shadow-light-xs' : ''}
        `}
      >
        <Link href={routes.index} className={open ? '' : 'hidden'}>
          <Image
            width={LogoBlack.width}
            height={LogoBlack.height}
            src={LogoBlack.src}
            alt="Logo de la web"
          />
        </Link>

        <Hamburger
          theme={theme}
          isOpen={open}
          onClick={() => setOpen(!open)}
          className={open ? 'z-30 ml-auto' : ''}
          aria-expanded={open}
          aria-controls="main-menu"
          aria-label="Abrir menú principal"
        />
      </div>

      <div
        className={oneLine`
          absolute inset-0 z-20 transform overflow-x-auto bg-bg-neutrals-0
          ${open ? '-translate-x-0' : '-translate-x-full'}
          drop-shadow-lg filter transition-transform duration-300 ease-in-out
        `}
      >
        <div className="mx-4 flex h-full flex-col gap-10">
          <ul className="pt-[calc(16px+var(--header-height))]" id="main-menu">
            {menu.map((ele, index: number) => {
              const isActive = (href: string | undefined) =>
                href
                  ? href === '/'
                    ? href === slug
                    : slug.includes(href)
                  : false;

              return (
                <li
                  key={index}
                  {...(ele.children &&
                    !ele.href && {
                      onClick: () =>
                        setNavItemStates(getUpdatedNavItemsStates(index)),
                    })}
                >
                  <NavItem
                    label={ele.label}
                    href={ele.href}
                    theme={theme}
                    isActive={isActive(ele.href)}
                    className="border-b border-bg-neutrals-50 px-2 py-6"
                    isOpen={navItemStates[index]}
                    {...(ele.children &&
                      ele.href && {
                        onClickIcon: () =>
                          setNavItemStates(getUpdatedNavItemsStates(index)),
                      })}
                  >
                    {ele.children && (
                      <div
                        className={oneLine`
                          ${navItemStates[index] ? 'flex' : 'hidden '}
                          bg-white text-text-primary inset-0 bottom-20 w-full lg:static lg:top-0 lg:group-hover:absolute lg:group-hover:right-0 lg:group-hover:left-0 lg:group-hover:-z-10 lg:group-hover:pt-header lg:group-hover:flex lg:group-hover:justify-center
                        `}
                        data-submenu-open={navItemStates[index]}
                      >
                        <ele.children.type
                          {...ele.children.props}
                          isActive={isActive}
                        />
                      </div>
                    )}
                  </NavItem>
                </li>
              );
            })}
          </ul>

          {topBar && (
            <div className="mt-auto pb-4">
              <div className="flex flex-col rounded-lg bg-primary-50 p-4">
                <div className="mb-4 flex flex-col gap-2">
                  <span className="text-body--l font-bold">
                    Dinos cómo quieres vivir
                  </span>

                  <span className="text-body--m">
                    ¿Buscas una vivienda para unos meses o para más de un año?
                  </span>
                </div>

                <div className="flex flex-col gap-2">
                  {topBar.map((child, index) => (
                    <Button
                      key={index}
                      as="a"
                      href={child.url}
                      variant={ButtonVariant.SECONDARY}
                      size={ButtonSize.SMALL}
                    >
                      {child.title}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {socials && (
            <div className="flex items-center justify-between pb-4 text-text-blue-900">
              <div className="text-body text-body--l font-bold">
                {socials.title}
              </div>
              <div className="flex gap-1">{social}</div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

MobileNav.displayName = 'MobileNav';
