import Router from 'next/router';

import { getUserID } from '@/utils';

export const debugGtm = (debugMsg: string, data: any): void => {
  const isProd: boolean = process.env.NODE_ENV === 'production';

  // eslint-disable-next-line no-console
  if (!isProd) console.log(`${debugMsg}: `, data);
};

export const getPageName = (): string | undefined => {
  const url: string = Router.asPath.split('?')[0];
  let pageName: string | undefined = undefined;

  // Get page name
  if (url === '/') {
    pageName = 'home';
  } else {
    const pathSplit = url.split('/').filter(Boolean);
    pageName = pathSplit[0];
  }

  return pageName;
};

export const gtmPush = async (data: any): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.gtmInit !== true) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gtmInit = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await window.dataLayer?.push({
      event: 'init',
      user: {
        userId: getUserID(),
        loggedIn: '0',
        visitor_type: 'external',
      },
      page: {
        page_type: getPageName(),
      },
    });
    sessionStorage.setItem('gtmInit', 'true');
  }

  if (data.event !== 'init') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer?.push(data);
  }

  debugGtm('GTM PUSH', data);
};
