import type { TypeAs, TypeChildren, TypeClassName } from '@/types';

export const enum ButtonVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  ELEVATED = 'ELEVATED',
  ALTERNATIVE = 'ALTERNATIVE',
}
export const enum ButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface ButtonProps<T extends React.ElementType>
  extends TypeClassName,
    TypeChildren,
    TypeAs<T> {
  /**
   * Indica el tipo de boton a mostrar
   */
  variant: ButtonVariant;
  /**
   * Indica el tipo de boton a mostrar
   */
  size: ButtonSize;
  /**
   * Si el botón es outline o no
   */
  outline?: boolean;
  /**
   * Si el botón tiene estilos de enlace
   */
  link?: boolean;
  /**
   * ¿El botón está cargando?
   *
   * @default false
   */
  loading?: boolean;
}
