export const routes = {
  index: '/',
  flats: {
    rent: {
      index: '/alquiler-pisos/viviendas',
      path: '/alquiler-pisos',
      zone: (zone: string) => `/alquiler-pisos/${zone}`,
    },
    half: {
      index: '/alquiler-temporal/viviendas',
      path: '/alquiler-temporal',
      zone: (zone: string) => `/alquiler-temporal/${zone}`,
    },
    sale: '/venta-pisos/viviendas',
    flat: (id: string) => `/vivienda/${id}`,
  },
  blog: {
    index: '/blog',
    post: (id: string) => `/blog/${id}`,
  },
  zones: {
    index: '/donde-estamos',
    zone: (zone: string) => `/donde-estamos/${zone}`,
  },
  404: '/404',
  contact: '/contacto',
  faqs: '/preguntas-frecuentes',
  legal: '/aviso-legal',
  privacy: '/politica-privacidad',
};
