import Image from 'next/image';

import { Container } from '@/atoms';

import { pickChild, pickChilds } from '@/utils';
import * as childs from './childs';

import type { FunctionComponent } from 'react';
import type { TypeFooterProps } from './types';

export const Footer: FunctionComponent<TypeFooterProps> = ({
  className = '',
  image,
  disclaimer,
  children,
}: TypeFooterProps) => {
  const [, columns] = pickChilds(children, childs.Column);
  const [, legal] = pickChild(children, childs.Legal);
  const [, socialMedia] = pickChild(children, childs.SocialMedia);

  return (
    <Container
      as="footer"
      wrapper
      padding="px-4 pt-10 pb-6 md:pb-16"
      backgroundColor="bg-primary-900"
      className={className}
    >
      <Image
        src={image.src}
        alt={image.alt ?? 'Footer Logo'}
        width={image.width}
        height={image.height}
        draggable="true"
        className="mb-6 md:mx-auto md:mb-8 xl:mx-0"
      />

      <div className="grid grid-cols-1 items-start gap-8 text-neutrals-0 md:grid-cols-2 md:py-6 md:text-center xl:grid-cols-4 xl:gap-12 xl:text-left">
        {columns}
      </div>

      <hr className="my-6 border-primary-800" />

      <div className="flex flex-col gap-12 md:items-center md:text-center xl:flex-row xl:justify-between xl:text-left">
        <div className="order-2 gap-2 md:order-1">{legal}</div>
        {socialMedia && <div className="order-1 md:order-2">{socialMedia}</div>}
      </div>

      {disclaimer && (
        <div className="text-body text-body--xs mt-6 text-text-neutrals-0">
          {disclaimer}
        </div>
      )}
    </Container>
  );
};

Footer.displayName = 'Footer';
