import type { TypeAs, TypeChildren, TypeClassName } from '@/types';

export enum LinkVariant {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export interface LinkProps<T extends React.ElementType>
  extends TypeChildren,
    TypeClassName,
    TypeAs<T> {
  /**
   * Estilo del link
   */
  variant?: LinkVariant;
  /**
   * Tipo de icono
   */
  icon?: 'left' | 'right';
  /**
   * Enlace desactivado
   */
  disabled?: boolean;
}
