import { useEffect } from 'react';

/**
 * Hook to fix an element that has full height and width.
 * @param {boolean} apply - if true the body is fixed.
 */
export const useFixPortalToBody = (apply: boolean) => {
  useEffect(() => {
    const body = window.document.getElementById('body-portal');

    if (body?.style && apply) {
      body.style.position = 'fixed';
      body.style.inset = '0';
    }

    return () => {
      if (body?.style) {
        body.style.position = '';
        body.style.inset = '';
      }
    };
  }, [apply]);
};
