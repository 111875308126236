import '../styles/globals.css';
import { NextSeo } from 'next-seo';
import Script from 'next/script';

import { Layout } from '@/components/Layout';

import type { TypeTheme } from '@/types';
import type { AppProps } from 'next/app';

interface PageProps {
  page: {
    title: string;
    metaDescription: string;
    metaKeywords: string;
    metaRobots: string;
    hidePreFooter: boolean;
    theme: TypeTheme;
    path: string;
    preview?: any;
    attributes?: any;
    menu?: any;
    preFooter?: any;
  };
}

interface MyAppProps extends AppProps<PageProps> {
  pageProps: PageProps;
}

const App = ({ Component, pageProps }: MyAppProps) => (
  <>
    {pageProps?.page?.preview && (
      <button
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
        onClick={() => {
          location.href = `${location.origin}/api/exit-preview?slug=${location.pathname}`;
        }}
      >
        Exit Preview
      </button>
    )}

    <NextSeo
      title={`${
        (pageProps?.page?.title ||
          pageProps?.page?.attributes?.Metatags?.MetaTitle) ??
        ''
      }  | Nestar`}
      description={
        (pageProps?.page?.metaDescription ||
          pageProps?.page?.attributes?.Metatags?.MetaDescription) ??
        ''
      }
      additionalMetaTags={[
        {
          name: 'robots',
          content: `${
            pageProps?.page?.attributes?.Metatags?.Index ? 'index' : 'noindex'
          },${
            pageProps?.page?.attributes?.Metatags?.Follow
              ? 'follow'
              : 'nofollow'
          }`,
        },
      ]}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
      ]}
      {...(pageProps?.page?.path && {
        canonical: `${process.env.NEXT_PUBLIC_FRONT_URL}${pageProps?.page?.path}`,
      })}
    />

    {process.env.NEXT_PUBLIC_GTM_ID &&
      process.env.NEXT_PUBLIC_GTM_ID !== 'GTM-XXXXXXX' && (
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
              `,
          }}
        />
      )}

    {process.env.NEXT_PUBLIC_COOKIE_PRO_ID && (
      <>
        <Script strategy="beforeInteractive" id="gtag">{`
          window.dataLayer = window.dataLayer || [];
          
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 500
          });
        `}</Script>

        <Script
          strategy="beforeInteractive"
          src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
          data-domain-script={process.env.NEXT_PUBLIC_COOKIE_PRO_ID}
        />

        <Script strategy="beforeInteractive" id="OptanonWrapper">{`
          function OptanonWrapper() {}
        `}</Script>
      </>
    )}

    <Layout
      theme={pageProps?.page?.theme}
      menu={pageProps?.page?.menu}
      preFooter={pageProps?.page?.preFooter?.attributes}
    >
      <Component {...pageProps} />
    </Layout>
  </>
);

export default App;
