import { oneLine } from 'common-tags';

import type { FunctionComponent } from 'react';
import type { TypeHamburgerProps } from './types';

const LINE =
  'h-0.5 w-full rounded-lg transition-all duration-300 ease-in-out bg-current';

/**
 * Hamburger
 */
export const Hamburger: FunctionComponent<TypeHamburgerProps> = ({
  className = '',
  onClick,
  isOpen,
  theme = 'light',
  ...props
}: TypeHamburgerProps) => (
  <button
    className={oneLine`
      relative flex h-8 w-10 flex-col items-center justify-between rounded-lg p-2
      ${theme === 'dark' && !isOpen ? 'text-neutrals-0' : 'text-neutrals-900'}
      ${className}
    `}
    type="button"
    onClick={onClick}
    {...props}
  >
    <span
      className={oneLine`
        ${LINE}
        ${isOpen ? 'translate-y-[7px] rotate-45 transform' : ''}
      `}
    />

    <span className={`${LINE} ${isOpen ? 'w-0' : 'w-full'}`} />

    <span
      className={oneLine`
        ${LINE}
        ${isOpen ? '-translate-y-[7px] -rotate-45 transform' : ''}
      `}
    />
  </button>
);

Hamburger.displayName = 'Hamburger';
