import { Disclosure } from '@headlessui/react';
import { oneLine } from 'common-tags';

import ChevronDown from '@/icons/light/chevron-down.svg';

import type { FunctionComponent } from 'react';
import type { TypeAccordionProps } from './types';

export const Accordion: FunctionComponent<TypeAccordionProps> = ({
  className,
  title,
  children,
  variant,
  color = 'blue',
}: TypeAccordionProps) => (
  <Disclosure
    as="div"
    className={oneLine`
      transition-colors duration-300 ease-in-out
      ${
        color === 'white'
          ? 'border-b border-solid border-neutrals-100 bg-neutrals-0 pb-4 text-neutrals-900 hover:border-neutrals-900'
          : 'rounded bg-primary-400 text-neutrals-0 hover:bg-primary-600'
      }
      ${className}
    `}
  >
    {({ open }) => (
      <>
        <Disclosure.Button
          className={oneLine`
            text-dropdown flex w-full items-center gap-3 p-4 text-left
            ${
              variant === 'link'
                ? 'uppercase tracking-widest text-primary-500'
                : 'justify-between'
            }
          `}
        >
          {title}

          <ChevronDown
            width={18}
            height={18}
            className={oneLine`flex-shrink-0
              ${color === 'white' ? 'text-neutrals-900' : 'text-neutrals-0'} 
              ${open ? 'rotate-180' : ''}
            `}
          />
        </Disclosure.Button>

        <Disclosure.Panel
          unmount={false}
          className={oneLine`
            text-body text-body--m p-4
            ${color === 'white' ? 'text-primary-900' : 'text-neutrals-0'}
          `}
        >
          {children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

Accordion.displayName = 'Accordion';
