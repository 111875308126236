import Image from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypeBackgroundImageProps } from './types';

/**
 * BackgroundImage
 */
export const BackgroundImage: FunctionComponent<TypeBackgroundImageProps> = ({
  backgroundImage,
  overlay,
  notLazy = false,
}: TypeBackgroundImageProps) => (
  <>
    {backgroundImage ? (
      typeof backgroundImage !== 'string' ? (
        <picture className="pointer-events-none absolute inset-0">
          {backgroundImage.srcset.map((source, index) => (
            <source media={source.media} srcSet={source.src} key={index} />
          ))}
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={backgroundImage.src}
            alt={backgroundImage.alt ?? ''}
            loading={notLazy ? undefined : 'lazy'}
            className="h-full w-full object-cover object-center"
          />
        </picture>
      ) : (
        <Image
          src={backgroundImage}
          alt="Card Background"
          className="absolute inset-0 h-full w-full object-cover object-center"
          draggable="false"
          priority={notLazy}
          sizes="100vw"
          fill
        />
      )
    ) : null}
    {overlay && overlay != 0 ? (
      <div
        className="absolute inset-0 bg-neutrals-900"
        style={{ opacity: overlay }}
      />
    ) : null}
  </>
);
