import { Footer } from './Footer';
import * as childs from './childs';

export type TypeFooter = typeof Footer & {
  Column: typeof childs.Column;
  Legal: typeof childs.Legal;
  SocialMedia: typeof childs.SocialMedia;
};

(Footer as TypeFooter).Column = childs.Column;
(Footer as TypeFooter).Legal = childs.Legal;
(Footer as TypeFooter).SocialMedia = childs.SocialMedia;

childs.Column.displayName = 'Footer.Column';
childs.Legal.displayName = 'Footer.Legal';
childs.SocialMedia.displayName = 'Footer.SocialMedia';

export type {
  LegalProps,
  SocialMediaProps,
  TypeColumnProps,
  TypeSocialMedia,
} from './childs/types';
export type { TypeFooterProps } from './types';
export default Footer as TypeFooter;
