import { forwardRef } from 'react';

import type { Ref } from 'react';
import '@/lib/utils/forwardRef';
import type { TextProps } from './types';

/**
 * Texto
 */
const TextBase = <T extends React.ElementType = 'p'>(
  {
    as,
    children,
    ...props
  }: TextProps<T> & Omit<React.ComponentPropsWithRef<T>, keyof TextProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'p';
  return (
    <HTMLTag {...props} ref={ref}>
      {children}
    </HTMLTag>
  );
};

export const Text = forwardRef(TextBase);
