import Image from 'next/image';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { ConditionalWrapper } from '@/hoc';

import type { FunctionComponent } from 'react';
import type { TypeCardProps } from './types';

/**
 * Card
 */
export const Card: FunctionComponent<TypeCardProps> = ({
  className,
  image,
  children,
  label,
  href,
  shadow = true,
  rounded = 'lg',
}: TypeCardProps) => (
  <div
    className={oneLine`
      ${rounded === 'lg' ? 'rounded-lg' : 'rounded'}
      ${shadow ? 'shadow-light-s' : ''}
      ${className?.card ?? ''}
    `}
  >
    {image && (
      <div className={`relative ${className?.image ?? ''}`}>
        <ConditionalWrapper
          condition={Boolean(href)}
          wrapper={(children) => (
            <Link
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              href={href}
              prefetch={false}
              className={`relative flex ${className?.image ?? ''}`}
            >
              {children}
            </Link>
          )}
        >
          <Image
            alt={image.alt ?? 'Imagen de la tarjeta'}
            src={image.src}
            draggable={false}
            fill
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            className={oneLine`
              object-cover
              ${rounded === 'lg' ? 'rounded-t-lg' : 'rounded-t'}
            `}
          />
        </ConditionalWrapper>
        {label}
      </div>
    )}
    <div className={className?.content ?? ''}>{children}</div>
  </div>
);

Card.displayName = 'Card';
