import { Fragment, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { useRouteChangeStart } from '@/hooks';
import { routes } from '@/lib';
import { DesktopNav } from './desktop-nav/DesktopNav';
import { MobileNav } from './mobile-nav/MobileNav';

import type { FunctionComponent } from 'react';
import type { TypeHeaderProps } from './types';

/**
 * Header
 */
export const Header: FunctionComponent<TypeHeaderProps> = ({
  className = '',
  theme = 'light',
  logo,
  menu,
  socials,
  topBar,
}: TypeHeaderProps) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenMobile, setOpenMobile] = useState(false);
  useRouteChangeStart(() => setOpen(false));

  return (
    <header
      className={oneLine`
        lg:[&:has([data-menu]:hover)]:bg-white lg:[&:has([data-menu]:hover)]:text-text-primary
        h-[var(--header-height)] border-b lg:h-auto
        [&:has([data-submenu-open="true"])_[data-button]]:hidden
        ${
          isOpen
            ? 'bg-white text-text-primary !fixed top-0 left-0 right-0 border-b border-secondary-200 lg:!relative lg:border-none lg:bg-transparent'
            : theme === 'dark'
            ? 'text-white'
            : 'text-text-primary'
        }
        ${theme === 'dark' ? 'border-neutrals-0/20' : 'border-neutrals-100'}
        ${className}
      `}
    >
      {topBar && (
        <div className="bg-primary-900">
          <div className="mx-auto hidden w-full max-w-[var(--wrapper-size)] flex-row items-center justify-end gap-4 py-1.5 px-6 lg:flex">
            {topBar.map((child, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <hr className="my-px h-5 w-px bg-neutrals-0 text-neutrals-0" />
                )}
                <Link className="text-body--m text-neutrals-0" href={child.url}>
                  {child.title}
                </Link>
              </Fragment>
            ))}
          </div>
        </div>
      )}

      <div className="mx-auto flex h-[var(--header-height)] w-full max-w-[var(--wrapper-size)] flex-row items-center justify-between lg:px-4">
        <Link
          href={routes.index}
          className={
            isOpenMobile ? 'hidden' : 'relative z-20 w-full pl-4 lg:pl-0'
          }
        >
          <Image
            width={logo.width}
            height={logo.height}
            src={logo.src}
            alt={logo.alt || 'Logo de la web'}
            priority
          />
        </Link>

        <div className="flex h-full w-full items-center justify-end pb-px">
          <DesktopNav menu={menu} theme={theme} />

          <MobileNav
            topBar={topBar}
            open={isOpenMobile}
            setOpen={setOpenMobile}
            menu={menu}
            socials={socials}
            theme={theme}
          />
        </div>
      </div>
    </header>
  );
};

Header.displayName = 'Header';
