import Link from 'next/link';
import { oneLine } from 'common-tags';

import ChevronDown from '@/icons/light/chevron-down.svg';
import ChevronUp from '@/icons/light/chevron-up.svg';

import { ConditionalWrapper } from '@/hoc';

import type { FunctionComponent } from 'react';
import type { TypeNavItemProps } from './types';

/**
 * NavItem
 */
export const NavItem: FunctionComponent<TypeNavItemProps> = ({
  label,
  href,
  theme,
  isActive,
  children,
  isOpen,
  className = '',
  onClickIcon,
}: TypeNavItemProps) => (
  <>
    <div
      className={`flex max-h-16 items-center justify-between lg:max-h-[none] ${className}`}
    >
      <ConditionalWrapper
        condition={Boolean(href)}
        wrapper={(children) => (
          <Link
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={href}
            prefetch={false}
            className={oneLine`
              ${isActive ? 'text-text-blue-500' : 'text-text-neutrals-900'}
              ${
                theme === 'dark'
                  ? 'lg:text-text-neutrals-0'
                  : 'lg:text-text-neutrals-900'
              }
            `}
          >
            {children}
          </Link>
        )}
      >
        <span className="text-headline text-headline--h4 whitespace-nowrap lg:text-button">
          {label}
        </span>
      </ConditionalWrapper>

      {children ? (
        <div
          {...(onClickIcon && { onClick: onClickIcon })}
          className="flex h-12 w-12 items-center justify-center lg:hidden"
        >
          {isOpen ? <ChevronUp width={24} /> : <ChevronDown width={24} />}
        </div>
      ) : null}
    </div>

    {children}
  </>
);

NavItem.displayName = 'NavItem';
