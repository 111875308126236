import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Container } from '@/atoms';
import { FlyOut } from '@/molecules';
import { Footer, Header } from '@/organisms';
import { Prefooter } from '@/templates';

import LogoBlack from '@/assets/images/LogoNestar-Black.png';
import LogoWhite from '@/assets/images/LogoNestar-White.png';
import LogoFooter from '@/assets/images/footer/Logo.png';
import CardGeneric from '@/assets/images/generic.jpg';
import { routes } from '@/lib';
import {
  getImageWithFallback,
  getPageName,
  getRealUrl,
  getUserID,
  gtmPush,
  toArray,
} from '@/utils';

export const Layout = ({ children, theme = 'light', menu, preFooter }: any) => {
  const logo = theme === 'light' ? LogoBlack : LogoWhite;
  const router = useRouter();

  useEffect(() => {
    gtmPush({
      event: 'init',
      user: {
        userId: getUserID(),
        loggedIn: '0',
        visitor_type: 'external',
      },
      page: {
        page_type: getPageName(),
      },
    });

    router.events.on('routeChangeComplete', () => {
      gtmPush({
        event: 'new_page',
        page: {
          page_type: getPageName(),
        },
      });
    });
  }, [router.events]);
  const topBarConAttributtes = menu?.find(
    (itm: any) => itm.attributes.slug === 'topMenu'
  )?.attributes.items.data;

  const topBar = topBarConAttributtes.map((val: any, indice: number) => ({
    id: indice,
    title: val.attributes.title,
    url: val.attributes.url,
  }));

  const mainMenuData = menu?.find(
    (itm: any) => itm.attributes.slug === 'mainMenu'
  )?.attributes?.items?.data;
  const mainMenu: any[] = [];

  mainMenuData?.forEach((itm: any) => {
    const parent = itm.attributes.parent.data;
    const submenu = toArray(
      mainMenuData?.filter(
        (item: any) => item.attributes.parent.data?.id === itm.id
      )
    )
      ?.filter(Boolean)
      ?.map((item: any) => ({
        href: item.attributes.url,
        title: item.attributes.title,
      }));

    if (!parent) {
      mainMenu.push({
        href: itm.attributes.url,
        label: itm.attributes.title,
        children:
          submenu.length === 0 ? null : (
            <FlyOut menu={submenu} className="z-10 bg-transparent" />
          ),
      });
    }
  });

  return (
    <div className="flex min-h-screen flex-col">
      <Header
        logo={{
          alt: 'Logo Nestar',
          height: logo.height,
          src: logo.src,
          width: logo.width,
        }}
        menu={mainMenu}
        theme={theme}
        topBar={topBar}
      />

      <main className="flex-grow">{children}</main>

      {router.asPath !== routes.index &&
        router.asPath !== routes.contact &&
        router.asPath !== routes.faqs &&
        router.asPath !== routes.privacy &&
        router.asPath !== routes.legal && (
          <Container wrapper id="faqs-contact">
            <Prefooter
              title={preFooter?.Titulo}
              description={preFooter?.Descripcion}
              accordions={preFooter?.pregunta_frecuentes?.data?.map(
                (itm: any) => ({
                  title: itm.attributes.Pregunta,
                  description: itm.attributes.Respuesta,
                })
              )}
              link={{
                label: 'Ver todas',
                href: routes.faqs,
              }}
              card={{
                title: preFooter?.TituloDerecha,
                image: getImageWithFallback(
                  {
                    src: getRealUrl(
                      preFooter?.ImagenDerecha?.data?.attributes?.url
                    ),
                    alt: preFooter?.ImagenDerecha?.data?.attributes
                      ?.alternativeText,
                  },
                  { src: CardGeneric.src, alt: 'Fallback image' }
                ),
                description: preFooter?.DescripcionDerecha,
                link: {
                  label: preFooter?.TextoBotonDerecha,
                  href: preFooter?.LinkBotonDerecha,
                },
              }}
            />
          </Container>
        )}

      <Footer
        image={{
          alt: 'Logo Nestar',
          height: LogoFooter.height,
          src: LogoFooter.src,
          width: LogoFooter.width,
        }}
        disclaimer="Las infografías mostradas en la Web, referentes a instalaciones de zonas comunes y piscina, están sujetas a ejecución del proyecto, no constituyendo carácter vinculante, ni contractual. Los elementos, tanto de ajardinamiento como decorativos, que figuran son orientativos. Toda la información relativa al Real Decrerto 515/89, de 21 de abril se encuentra en nuestras oficinas. Las imágenes, referentes a cocinas y demás dependencias de las viviendas publicitadas, referentes a mobiliarios, enseres y plantas mostrados, son ilustrativas y decorativas, no constituyendo carácter vinculante, ni contractual."
        className={
          router.asPath.includes(routes.flats.flat('')) ? 'mb-16 md:mb-0' : ''
        }
      >
        <Footer.Column
          menu={menu
            ?.find((itm: any) => itm.attributes.slug === 'footer-menu-column-1')
            ?.attributes?.items?.data.map((itm: any) => ({
              href: itm.attributes.url,
              label: itm.attributes.title,
            }))}
          title={
            menu?.find(
              (itm: any) => itm.attributes.slug === 'footer-menu-column-1'
            )?.attributes.title || 'Sobre Nosotros'
          }
        />
        <Footer.Column
          menu={menu
            ?.find((itm: any) => itm.attributes.slug === 'footer-menu-column-2')
            ?.attributes?.items?.data.map((itm: any) => ({
              href: itm.attributes.url,
              label: itm.attributes.title,
            }))}
          title={
            menu?.find(
              (itm: any) => itm.attributes.slug === 'footer-menu-column-2'
            )?.attributes.title || 'Nuestras Viviendas'
          }
        />
        <Footer.Column
          menu={menu
            ?.find((itm: any) => itm.attributes.slug === 'footer-menu-column-3')
            ?.attributes?.items?.data.map((itm: any) => ({
              href: itm.attributes.url,
              label: itm.attributes.title,
            }))}
          title={
            menu?.find(
              (itm: any) => itm.attributes.slug === 'footer-menu-column-3'
            )?.attributes.title || 'Nuestros servicios'
          }
        />
        <Footer.Column
          title={{
            href: routes.contact,
            label:
              menu?.find(
                (itm: any) => itm.attributes.slug === 'footer-menu-column-4'
              )?.attributes.title || 'Contáctanos',
          }}
          menu={menu
            ?.find((itm: any) => itm.attributes.slug === 'footer-menu-column-4')
            ?.attributes?.items?.data.map((itm: any) => ({
              href: itm.attributes.url,
              label: itm.attributes.title,
            }))}
        />
        <Footer.Legal
          legales={[
            '© 2022 | All rights reserved',
            ...menu
              ?.find((itm: any) => itm.attributes.slug === 'legal')
              ?.attributes?.items?.data?.map((itm: any) => ({
                href: itm.attributes.url,
                label: itm.attributes.title,
              })),
          ]}
        />
      </Footer>
    </div>
  );
};

Layout.displayName = 'Layout';
