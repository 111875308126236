import { useState } from 'react';
import { useRouter } from 'next/router';
import { oneLine } from 'common-tags';

import { useRouteChangeStart } from '@/hooks';
import NavItem from '../nav-item';

import type { TypeDesktopNavProps } from './types';

/**
 * DesktopNav
 */
export const DesktopNav = ({ menu, theme }: TypeDesktopNavProps) => {
  const { asPath: slug } = useRouter();
  const isActive = (href: string | undefined) =>
    href ? (href === '/' ? href === slug : slug.includes(href)) : false;

  const initialNavItemsState = new Array(menu.length).fill(false);
  const [navItemStates, setNavItemStates] = useState(initialNavItemsState);
  useRouteChangeStart(() => setNavItemStates(initialNavItemsState));

  const getUpdatedNavItemsStates = (
    navItemIndex: number,
    navItemIndexNewState: boolean
  ) => {
    const navItemsStatesClone = [...navItemStates];
    navItemsStatesClone[navItemIndex] = navItemIndexNewState;
    return navItemsStatesClone;
  };

  return (
    <nav className="hidden lg:block">
      <ul className="flex gap-6">
        {menu.map((ele, index: number) => (
          <li
            key={index}
            className="relative flex flex-col justify-center"
            {...(ele.children && {
              onMouseEnter: () =>
                setNavItemStates(getUpdatedNavItemsStates(index, true)),
              onMouseLeave: () =>
                setNavItemStates(getUpdatedNavItemsStates(index, false)),
            })}
          >
            <NavItem
              label={ele.label}
              href={ele.href}
              theme={theme}
              isActive={isActive(ele.href)}
              className={oneLine`
                h-20 border-b-2 px-2
                ${
                  isActive(ele.href)
                    ? 'border-primary-500'
                    : 'border-transparent'
                }
              `}
            >
              {ele.children && (
                <div
                  className={oneLine`
                    top-full left-0 z-20 lg:absolute lg:w-[240px] lg:before:block lg:before:h-2 lg:before:w-full lg:before:content-['']
                    ${navItemStates[index] ? '' : 'hidden'}
                  `}
                >
                  <div className="bg-white text-text-primary absolute inset-0 bottom-20 flex w-full lg:static lg:top-0 lg:group-hover:absolute lg:group-hover:right-0 lg:group-hover:left-0 lg:group-hover:-z-10 lg:group-hover:pt-header lg:group-hover:flex lg:group-hover:justify-center">
                    <ele.children.type
                      {...ele.children.props}
                      closed={() =>
                        setNavItemStates(getUpdatedNavItemsStates(index, false))
                      }
                    />
                  </div>
                </div>
              )}
            </NavItem>
          </li>
        ))}
      </ul>
    </nav>
  );
};

DesktopNav.displayName = 'DesktopNav';
