import Link from 'next/link';

import { ConditionalWrapper } from '@/hoc';

import type { FunctionComponent } from 'react';
import type { TypeColumnProps } from './types';

/**
 * Column
 */
export const Column: FunctionComponent<TypeColumnProps> = ({
  className = '',
  title,
  menu,
}: TypeColumnProps) => (
  <div className="flex flex-col gap-4">
    {title && (
      <ConditionalWrapper
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        condition={Boolean(title?.href)}
        wrapper={(children) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Link href={title.href} legacyBehavior prefetch={false}>
            <a className="text-text-neutrals-0 hover:opacity-80">{children}</a>
          </Link>
        )}
      >
        <span className="text-body text-body--l font-bold">
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            title?.label || title
          }
        </span>
      </ConditionalWrapper>
    )}

    {menu && (
      <div
        className={`text-body text-body--m flex flex-col gap-2 text-neutrals-0 md:gap-4 ${className}`}
      >
        {menu?.map((item, index: number) => (
          <Link key={index} href={item.href} legacyBehavior>
            <a className="py-1 text-text-neutrals-0 hover:opacity-80 md:py-0">
              {item.label}
            </a>
          </Link>
        ))}
      </div>
    )}
  </div>
);

Column.displayName = 'Column';
