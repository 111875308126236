import type { TypeImage } from '@/types';

// TODO: Convertir a una función con Generics
/**
 * Convert the given value into an array
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {any} item The value to convert
 * @returns {any[]} The converted value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toArray = (item: any): any[] =>
  Array.isArray(item) ? item : [item];

/**
 * Get the extension of the given URL
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} url The URL to get the extension
 * @returns {string} The extension of the URL
 */
// eslint-disable-next-line arrow-body-style
export const getUrlExtension = (url: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return url?.split(/[#?]/)[0].split('.').pop()!.trim();
};

/**
 * Check if the given extension is a PNG
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} extension The extension to check
 * @returns {boolean} True if the extension is a PNG
 */
export const isPNG = (extension: string): boolean =>
  extension?.toLowerCase() === 'png';

/**
 * Return an image with the fallback image if the given image is not valid
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {TypeImage} image The image to check
 * @param {TypeImage} fallback The fallback image
 * @returns {TypeImage} The image with the fallback image if the given image is not valid
 */
export const getImageWithFallback = (
  image: TypeImage,
  fallback: TypeImage
): TypeImage => ({
  src: image?.src && image.src !== '' ? image.src : fallback.src,
  alt: image?.alt && image.alt !== '' ? image.alt : fallback.alt,
});

/**
 * Check if the current environment is production
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @returns {boolean} True if the current environment is production
 */
export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production';

/**
 * Show a debug message in the console if the current environment is not production
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} message The message to show
 * @param {any[]} optionalParams Optional parameters to show
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debug = (message?: any, ...optionalParams: any[]): void => {
  // eslint-disable-next-line no-console
  if (!isProduction()) console.log(message, ...optionalParams);
};

/**
 * Show a debug message in the console if the current environment is not production
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} message The message to show
 * @param {any[]} optionalParams Optional parameters to show
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debugServer = (message?: any, ...optionalParams: any[]): void => {
  // eslint-disable-next-line no-console
  console.log(message, ...optionalParams);
};

/**
 * Remove the back base URL from the given URL
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} url The URL to remove the back base URL
 * @returns {string} The URL without the back base URL
 */
export const removeBackBaseURL = (url: string): string =>
  url.replace(process.env.NEXT_PUBLIC_BACK_URL ?? '', '');

/**
 * Format a number to the correct currency format
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {number} amount The amount to format
 * @param {number} minDigits Minimun fraction digits
 * @param {number} maxDigits Maximun fraction digits
 * @returns {string} The formatted amount
 */
export const formatNumberToCurrency = (
  amount: number,
  minDigits = 0,
  maxDigits = 0
): string => {
  const hasDecimalsWithZero = /^-?\d+(\.0+)?$/.test(amount.toString());
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: hasDecimalsWithZero ? 0 : minDigits,
    maximumFractionDigits: hasDecimalsWithZero ? 0 : maxDigits,
    useGrouping: true,
  }).format(amount);
};

/**
 * Capitalize the first letter of the given string
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} text The text to capitalize
 * @returns {string} The capitalized text
 */
export const capitalize = (text: string): string =>
  text
    ?.toLowerCase()
    .split('-')
    .join(' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());

/**
 * Return the path of the given URL
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} url The URL to get the path
 * @returns {string} The path of the URL
 */
export const getPathFromUrl = (url: string): string =>
  url.replace(/(\?.*)|(#.*)/g, '');

/**
 * Return the query string of the given URL
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tome@habitant.es>
 *
 * @param {string} url The URL to get the query string
 * @returns {URLSearchParams} The query string of the URL
 */
export const getQueryParams = (url: string): URLSearchParams =>
  new Proxy(new URLSearchParams(url), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });

export const getRealUrl = (url: string | undefined) =>
  url
    ? `${process.env.NEXT_PUBLIC_STRAPI_BAKEND?.replace('/graphql', '')}${
        url || '/'
      }`
    : '';

export const getImageWithFallBackWithOutAlt = (
  src: string,
  fallback: string
): string => (src !== '' ? src : fallback);

/**
 * Checks if the object passed as argument is empty or not
 * @since 1.0.0
 * @author Andrea Cabral <andrea.cabral@globant.com>
 *
 * @param value Object to check if it is empty
 * @returns True is object is empty and false if not
 */
export const isEmptyObject = (value: object) =>
  Object.entries(value).length === 0;

/**
 * Helper function to generate the hierarchical name, label name and path of a category
 * @since 1.0.0
 * @author Rodrigo Tomé <rodrigo.tomel@globant.com>
 *
 * @param categories Array of categories
 * @returns Object with hierarchical name, label name and path
 */
export const generateHierarchical = (categories: string[]) => {
  const province = categories[0];
  const city = categories[1] ?? null;
  const zone = categories[2] ?? null;
  const rest = categories.slice(3).join('/');
  const hierarchical = `${province ? province : ''}${
    city ? ` > ${city.replaceAll('-', ' ')}` : ''
  }${zone ? ` > ${zone.replaceAll('-', ' ')}` : ''}${rest}`;
  const name = `${capitalize(province)} ${city ? `> ${capitalize(city)}` : ''}${
    zone ? ` > ${capitalize(zone)}` : ''
  }`;
  const path = `${province ?? ''}${city ? `/${city}` : ''}${
    zone ? `/${zone}` : ''
  }`;

  return { hierarchical, name, path };
};
