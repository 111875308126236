import NextLink from 'next/link';

import { Accordion, Button, Card, Link, Text } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';
import { LinkVariant } from '@/atoms/link/types';

import type { FunctionComponent } from 'react';
import type { TypePrefooterProps } from './types';

/**
 * Prefooter
 */
export const Prefooter: FunctionComponent<TypePrefooterProps> = ({
  className = '',
  title,
  description,
  accordions,
  link,
  card,
}: TypePrefooterProps) => (
  <div
    className={`flex flex-col items-center gap-6 lg:flex-row lg:items-start ${className}`}
  >
    <div className="w-full rounded-lg bg-primary-500 py-6 px-4 md:py-10 md:px-16">
      <Text
        className="text-headline text-headline--h3 mb-4 text-neutrals-0"
        as="h2"
      >
        {title}
      </Text>

      <Text className="text-body text-body--l mb-6 text-neutrals-0" as="p">
        {description}
      </Text>

      <div className="mb-6 flex flex-col gap-y-4">
        {accordions.map((content, index: number) => (
          <Accordion title={content.title} variant="normal" key={index}>
            {content.description}
          </Accordion>
        ))}
      </div>

      <Link href={link.href} icon="right" variant={LinkVariant.NEGATIVE}>
        {link.label}
      </Link>
    </div>

    <Card
      className={{
        card: 'w-full md:max-w-sm',
        content: 'text-body bg-neutrals-50 py-12 px-8',
        image: 'h-[320px] w-full',
      }}
      image={card.image}
      shadow={false}
    >
      <Text className="fond-bold text-headline text-headline--h4 mb-2">
        {card.title}
      </Text>

      <Text className="text-body--l mb-6">{card.description}</Text>

      <NextLink href={card.link.href} prefetch={false}>
        <Button
          as="span"
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.SECONDARY}
          className="!w-max text-text-neutrals-900"
        >
          {card.link.label}
        </Button>
      </NextLink>
    </Card>
  </div>
);

Prefooter.displayName = 'Prefooter';
